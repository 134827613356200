<template>
  <div class="_desc">
    <h2 class="font-bold text-xl mb-12">Edit Account :</h2>
    <a-form
      layout="vertical"
      title="Personal Info"
      :rules="rules"
      :model="formData"
      ref="editForm"
    >
      <a-row :gutter="24">
        <a-col :xs="24" :md="24" :lg="12">
          <a-form-item label="Brand Name:" name="brandName">
            <a-input v-model:value="formData.brandName" />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :md="24" :lg="12">
          <a-form-item label="Phone Number" name="phone">
            <a-input v-model:value="formData.phone" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :xs="24" :md="24" :lg="12">
          <a-form-item label="Company Website:" name="companySite">
            <a-input
              v-model:value="formData.companySite"
              placeholder="www.example.com"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :md="24" :lg="12">
          <a-form-item label="Website Type:">
            <a-select v-model:value="formData.websiteType">
              <a-select-option value="website">Website</a-select-option>
              <a-select-option value="E-commerce">
                E-Commerce Website</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :xs="24" :md="24" :lg="12">
          <a-form-item label="Twitter Link" name="twitter">
            <a-input v-model:value="formData.twitter" />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :md="24" :lg="12">
          <a-form-item label="Instagram Link:" name="instagram">
            <a-input
              v-model:value="formData.instagram"
              placeholder="www.example.com"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :xs="24" :md="24" :lg="12">
          <a-form-item label="Facebook Link" name="facebook">
            <a-input v-model:value="formData.facebook" />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :md="24" :lg="12">
          <a-form-item label="Description:" name="description">
            <a-textarea
              v-model:value="formData.description"
              placeholder="Tell users about your brand..."
              @keypress.enter="handleUpdate"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :xs="24" :md="24" :lg="12">
          <a-form-item class="mt-10">
            <app-button
              buttonText="Update Account"
              buttonClass="w-full"
              :loading="formLoading"
              @buttonAction="handleUpdate"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import useLoadingState from '@/use/loading-state'

export default {
  name: 'EditDetails',
  props: {
    designer: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  setup(props) {
    const store = useStore()
    const { formLoading } = useLoadingState()
    const formData = ref({})
    const editForm = ref()
    const rules = {
      brandName: [
        {
          required: true,
          message: 'Brand name is required',
          trigger: 'blur'
        }
      ],
      phone: [
        {
          required: true,
          message: 'contact phone is required',
          trigger: 'blur'
        }
      ],
      description: [
        {
          required: true,
          message: 'Brand name is required',
          trigger: 'blur'
        }
      ]
    }
    onMounted(() => {
      formData.value = props.designer
    })
    watch(
      () => props.designer,
      (newDesigner) => {
        formData.value = newDesigner
      }
    )
    const handleUpdate = () => {
      // debugger
      store.dispatch('designer/UpdateDesigner', formData.value)
    }
    return { rules, formData, editForm, handleUpdate, formLoading }
  }
}
</script>

<style scoped>
._desc {
  width: 95%;
  margin: 0px auto;
}
</style>
